<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <ValidationObserver
        ref="form"
      >
        <cliente-cuenta-form
          @change-values="changeFormData"
        />
      </ValidationObserver>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import ClienteCuentaForm from '../components/ClienteCuentaForm'
import Data from './ClienteCuentaAddData'

export default {
  components: {
    ClienteCuentaForm
  },
  mixins: [formPageMixin],
  props: {
    viewRoute: {
      type: String,
      default: '',
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Añadir cuenta bancaria'
    },
    changeFormData (formData) {
      this.formData = formData
    },
    async submitForm () {
      // máscara IBAN
      if (this.formData.iban) {
        this.formData.iban = this.formData.iban.replace(/ /g, '')
      }
      const existeIban = await this.$online.clienteCuenta.existeIban(this.routeParams.idcliente, this.routeParams.idcliente_potencial, this.formData.iban)
      if (!existeIban) {
        const inserted = await Data.insert(
          this, this.formData, this.routeParams.idcliente, this.routeParams.idcliente_potencial
        )
        this.$appRouter.replace({
          name: this.viewRoute,
          params: {
            idcliente_cuenta: inserted.idcliente_cuenta,
            idcliente_potencial: this.routeParams.idcliente_potencial,
            idcliente: this.routeParams.idcliente,
          },
        })
      } else {
        this.$alert.showSnackbarError('El IBAN ya existe en éste cliente')
      }
    },
  },
}
</script>
